.swiper-pagination {
  left: 20px !important; /* Adjust left spacing */
  right: 20px !important;
  width: auto;
  display: flex;
  flex-direction: column; /* Stack dots vertically */
  align-items: flex-end;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}