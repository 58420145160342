/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "TwentiethCentury";
    font-style: normal;
    font-weight: 100 200 300 400 500 600 700 800 900;
    font-display: optional;
    src: url("./fonts/6138beb83221f6eb46ed08543a304d1d.woff2")
      format("woff2");
  }
}

/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */
.custom-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.custom-content li {
  margin-bottom: 8px;
}

.custom-content a {
  color: #15ab9e;
  text-decoration: underline;
}

.custom-content a:hover {
  color: #0e8476;
}
.new_homebanner {
  transform: scale(1.1) !important;
  transition-duration: 4s !important;
}
/* .slick-slideimg {
  transform: scale(0.95);
  transition: transform 2s ease-in-out;
} */
.slick-slide.slick-active.slick-current img {
  transform: scale(1) !important;
  transition-duration: 2s !important;
  z-index: 99999 !important;
  position: relative !important;
  /*object-fit: cover !important;*/
}
.slick-slide.slick-active.slick-current {
  object-fit: cover !important;
  overflow: hidden !important;
}
.swiper-img {
  display: block;
  width: 30px !important;
  height: 30px !important;
  object-fit: cover;
}
.slider-top {
  background-color: #06ab9f !important;
}
.gift-img {
  width: 20px !important;
  height: 20px !important;
}
.insta {
  margin: 10px !important;
  box-shadow: 0px 0px 8.1px 1px #39393929;
  border-radius: 15px;
  padding: 15px;
}
@media (max-width: 768px) {
  .responsive-svg {
    width: 24px;
    height: 24px;
  }
  .main-img {
    width: 45px !important;
    height: 45px !important;
  }
  .hover-img {
    width: 60px !important;
    height: 80px !important;
  }
}
.productcareparagraph {
  line-height: 30px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 75%) !important;
}
.customfaq-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.customfaq-content li {
  margin-bottom: 8px;
  color: #64636b;
}
.ReactModal__Content.ReactModal__Content--after-open {
  height: 655px !important;
  padding: 0px 20px !important;
}
.customfaq-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.customfaq-content li {
  margin-bottom: 8px;
  color: #64636b;
}
.custom-content p span{
  line-height: 28px !important;
  letter-spacing: -1%;
}

#container {
  margin: 1rem;
}

.repo-title {
  margin-bottom: 1.5rem;
}

.listing {
  margin-bottom: 1rem;
}
.pagination{
  display: flex;
}
.page-item{
  padding: 5px;
}
.pagination .active > .page-link {
  color: black !important;
  background-color: white !important;
  scale: 1.14;
  font-weight: 600;
  font-size: 24px;
  text-decoration: underline;
  text-decoration-color: #06ab9f;
}

.pagination .page-link {
  color: black !important;
  padding: 8px 10px;
  font-weight: 300;
  font-size: 24px;
}
.pagination .disabled > .page-link {
  display: none;
}
.page-item:last-child .page-link {
  background-color: #15ab9e;
  color: white !important;
}
.page-item:first-child .page-link {
  background-color: #15ab9e;
  color: white !important;
}

.p-career-title p:first-of-type {
  padding-bottom: 20px;
}
.newsletter-form::after{
  position: absolute;
      content: '';
      left: -2px;
      right: -2px;
      top: -2px;
      bottom: -2px;
      z-index: -1;
}
.newsletter-form input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 14px;
  color: #A59D9D;
  padding-right: 200px;
  font-weight: 300;
  font-size: 17px;
}
  .newsletter-form button {
    position: absolute;
    top: 0px;
    right: -2px;
    bottom: 0px;
    width: 115px;
    outline: none;
    border: none;
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer; 
  }